import {
  AfterViewInit,
  Component,
  ElementRef,
  forwardRef,
  Injector,
  Input,
  OnInit,
  Renderer2,
  inject,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { setIonicClasses } from '@ionic/angular/common';
import { IonInput, IonItem } from '@ionic/angular/standalone';
import { MaskitoDirective } from '@maskito/angular';
import { MaskitoOptions } from '@maskito/core';
import { maskitoTimeOptionsGenerator } from '@maskito/kit';
import { fixFormControlMarkAs, getNestedErrors } from '@techniek-team/common';
import { format, isDate, isValid, parse } from 'date-fns';
import { Subject, takeUntil } from 'rxjs';
import { map } from 'rxjs/operators';

type OnChangeCallback = (_data: Date | null) => void;
type OnCallback = () => void;

@Component({
  selector: 'tt-time-control',
  templateUrl: './tt-time-input-control.component.html',
  styleUrls: ['./tt-time-input-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TtTimeInputControlComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => TtTimeInputControlComponent),
      multi: true,
    },
  ],
  standalone: true,
  host: {
    '[class]': 'this.fill',
  },
  imports: [ReactiveFormsModule, MaskitoDirective, IonItem, IonInput],
})
export class TtTimeInputControlComponent
  implements ControlValueAccessor, OnInit, AfterViewInit, Validator
{
  private readonly element = inject(ElementRef);

  private readonly injector = inject(Injector);

  private readonly renderer = inject(Renderer2);

  public readonly timePattern: string = '^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$';

  /**
   * The fill for the item. If `'solid'` the item will have a background. If
   * `'outline'` the item will be transparent with a border.
   * Only available in `md` mode.
   */
  @Input() public fill: 'outline' | 'solid' | undefined = undefined;

  /**
   * If `true`, a clear icon will appear in the input when there is a value. Clicking it clears the input.
   */
  @Input() public clearInput: boolean = false;

  /**
   * Represents a label.
   */
  @Input() public label?: string;

  @Input() public labelPlacement?: string;

  @Input() public ariaLabel?: string;

  @Input() public lines?: 'full' | 'inset' | 'none' = undefined;

  @Input() public helperText?: string;

  @Input() public errorText?: string;

  /**
   * If `true`, the value will be cleared after focus upon edit. Defaults to `true` when `type` is `"password"`,
   * `false` for all other types.
   */
  @Input() public clearOnEdit?: boolean;

  @Input() public date?: Date;

  /**
   * @inheritDoc
   */
  public onChange: OnChangeCallback = (_data: Date | null) => {
    /* callback */
  };

  /**
   * @inheritDoc
   */
  public onTouch: OnCallback = () => {
    /* callback */
  };

  protected timeControl = new FormControl<string | null>(null, [
    Validators.pattern(this.timePattern),
  ]);

  /**
   * Subject which completes all hot observers on emit.
   */
  private onDestroy$: Subject<void> = new Subject<void>();

  /**
   * The last date given through a writeValue;
   */
  private originalDate!: Date;

  protected maskItoConfig!: MaskitoOptions;

  /**
   * @inheritDoc
   */
  public ngOnInit(): void {
    this.maskItoConfig = maskitoTimeOptionsGenerator({
      mode: 'HH:MM',
    });
    this.createOnChangeObserver();
  }

  public ngAfterViewInit(): void {
    fixFormControlMarkAs(this.injector, this.timeControl, this.element);
    const ionItem: HTMLElement = this.element.nativeElement.parentElement;
    if (ionItem.nodeName === 'ION-ITEM') {
      this.renderer.addClass(ionItem, 'tt-time-control');
    }
  }

  /**
   * @inheritDoc
   */
  public registerOnChange(fn: OnChangeCallback): void {
    this.onChange = fn;
  }

  /**
   * @inheritDoc
   */
  public registerOnTouched(fn: OnCallback): void {
    this.onTouch = fn;
  }

  /**
   * @inheritDoc
   */
  public setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.timeControl.disable();
      return;
    }
    this.timeControl.enable();
  }

  /**
   * @inheritDoc
   */
  public validate(control: AbstractControl): ValidationErrors | null {
    if (this.timeControl.invalid) {
      return getNestedErrors(this.timeControl);
    }

    if (control.validator !== null) {
      return control.validator(this.timeControl);
    }

    return null;
  }

  /**
   * @inheritDoc
   */
  public writeValue(data: Date | null): void {
    if (data === null) {
      this.originalDate = this.date ?? new Date();
      this.timeControl.reset();
      return;
    }

    if (isDate(data)) {
      this.originalDate = this.date ?? data;
      this.timeControl.reset(format(data, 'HH:mm'));
    }
    setIonicClasses(this.element);
  }

  protected maskPredicate(el: Element): Promise<HTMLInputElement> {
    return (el as HTMLIonInputElement).getInputElement();
  }

  /**
   * Create a subscriber which emits the changed values to the onChange.
   */
  private createOnChangeObserver(): void {
    this.timeControl.valueChanges
      .pipe(
        takeUntil(this.onDestroy$),
        map((value) => {
          if (typeof value === 'string') {
            return parse(value, 'HH:mm', this.originalDate);
          }
          return isValid(value) ? value : null;
        }),
      )
      .subscribe((changed) => {
        this.onTouch();
        this.onChange(changed);
      });
  }
}
