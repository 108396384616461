@if (!fill) {
  <ion-item [lines]="lines" class="item-interactive" [disabled]="timeControl.disabled">
    <ion-input
      [formControl]="timeControl"
      [fill]="fill"
      [label]="label"
      [labelPlacement]="labelPlacement"
      [maskito]="maskItoConfig"
      (ionInput)="onTouch()"
      [maskitoElement]="maskPredicate"
      (ionFocus)="onTouch()"
      [helperText]="helperText"
      [errorText]="errorText"
      data-test="time-input"
      type="text"
      [clearInput]="clearInput"
      [clearOnEdit]="clearOnEdit"
    >
    </ion-input>
  </ion-item>
} @else {
  <ion-input
    [formControl]="timeControl"
    [fill]="fill"
    [label]="label"
    [labelPlacement]="labelPlacement"
    [maskito]="maskItoConfig"
    (ionInput)="onTouch()"
    [maskitoElement]="maskPredicate"
    (ionFocus)="onTouch()"
    [helperText]="helperText"
    [errorText]="errorText"
    data-test="time-input"
    type="text"
    [clearInput]="clearInput"
    [clearOnEdit]="clearOnEdit"
  >
  </ion-input>
}
<ng-content select="ion-note"></ng-content>
